.QuestionRange .range-question__radio-button > div{
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.QuestionRange .range-question__radio-button > div > div{
    margin: 0 auto !important
}

.QuestionRange .range-question__radio-button > div > label{
    position: static !important;
    width: initial !important;
    display: initial !important;
    float: initial !important;
}